/**
 * Wrapper
 */
.syn-station-reading-norm {
  position: relative;

  width: 140px;
  height: 130px;
  max-width: 100%;
}

.syn-station-reading-norm--type-mono {
  width: 100px;
  height: 100px;
}

/** Data wrapper */
.syn-station-reading-norm__data {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/** ion-card-header padding */
.syn-station-reading-norm--type-mono > .syn-station-reading-norm__data {
  margin-top: -16px;
}

/** Value */
.syn-station-reading-norm__value {
  font-size: 54px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -.025em;
}

.syn-station-reading-norm--type-mono .syn-station-reading-norm__value {
  font-size: 38px;
}

/** Unit */
.syn-station-reading-norm__unit {
  font-size: 14px;
  display: none;
}

/** Label */
.syn-station-reading-norm__label {
  z-index: 1;
  font-size: 15px;
  font-weight: 300;
  line-height: 1;
}

/** Hint */
.syn-station-reading__hint {
  position: absolute;
  bottom: 0;
  right: 0;

  font-size: 26px;
}
