/**
 * Virtuoso tweaks
 * @see https://beta.ionicframework.com/docs/react/virtual-scroll
 */
.syn-stations-list {
  height: 100%;
}

.syn-stations-list .syn-stations-list__item-wrapper {
  height: 80px;
}

/** Default 2-line is 66 */
.syn-stations-list ion-item {
  --min-height: 80px;

  /** Fix inset lines by toggle these two (known issue) */
  /* --padding-end: 16px; */
  /* --inner-padding-end: 0; */
}

/**
 * Searchbar
 */
.syn-toolbar-searchbar ion-label {
  /** Safari 12.0 workaround */
  padding-left: 16px;
  padding-right: 16px;

  padding-inline-start: 16px;
  padding-inline-end: 16px;

  font-size: .85em;
  font-weight: 700;
}

.syn-toolbar-searchbar ion-searchbar {
  --background: transparent;
  --box-shadow: none;

  /** Safari 12.0 workaround */
  padding-left: 16px;
  padding-right: 16px;

  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

.syn-toolbar-searchbar input.searchbar-input {
  /** Safari 12.0 workaround */
  padding-left: unset;
  padding-right: unset;
}

.syn-toolbar-searchbar .searchbar-input-container {
  border-bottom: 2px solid var(--ion-color-primary);
}

.syn-toolbar-searchbar ion-searchbar .searchbar-search-icon {
  display: none !important;
}

.syn-toolbar-searchbar ion-searchbar .searchbar-input {
  /** With disabled icons */
  padding-inline-start: unset !important;
  padding-inline-end: unset !important;
}

/**
 * List item
 */
.syn-stations-list ion-label > .syn-station-city { font-weight: bold; }

.syn-stations-list ion-label > .syn-station-address { margin-top: 6px; }

.syn-stations-list ion-label > .syn-station-address,
.syn-stations-list ion-label > .syn-station-city { display: block; }

/**
 * Adapt styles for search bar withn ion toolbar
 */
ion-toolbar.syn-toolbar-searchbar ion-buttons.ios { align-self: center; }
ion-toolbar.syn-toolbar-searchbar ion-searchbar.ios { padding-bottom: 1px; }
