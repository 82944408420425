/**
 * Color Wheel
 * Note: url references break when using base href (need to prepend path)
 * Fixed in Safari 13.1 (2020-04-03)
 * @link https://github.com/airbnb/lottie-web/issues/360#issuecomment-750913563
 */
.syn-color-wheel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  transform: rotate(10deg);

  transition: color 250ms ease-in-out;
}

/** Grade A */
.syn-color-wheel.syn-color-wheel--grade-grade_a { color: #54d781; }
/** Grade B */
.syn-color-wheel.syn-color-wheel--grade-grade_b { color: #c0e36e; }
/** Grade C */
.syn-color-wheel.syn-color-wheel--grade-grade_c { color: #ffe851; }
/** Grade D */
.syn-color-wheel.syn-color-wheel--grade-grade_d { color: #ff854f; }
/** Grade E */
.syn-color-wheel.syn-color-wheel--grade-grade_e { color: #ea3a39; }
/** Grade F */
.syn-color-wheel.syn-color-wheel--grade-grade_f { color: #af0100; }
/** Unknown */
.syn-color-wheel.syn-color-wheel--grade-unknown { color: #888; }
/** Skeleton, color same as ion-skeleton-text --background */
.syn-color-wheel.syn-color-wheel--grade-skeleton { color: rgba(35, 87, 112, 0.067); }

/** Color fillable elements */
.syn-color-wheel .syn-color-wheel__track,
.syn-color-wheel .syn-color-wheel__thumb { fill: currentColor; }

.syn-color-wheel__track { mask: url(#syn-color-wheel__mask); }

/**
 * 6-grade specific
 */

.syn-color-wheel .syn-color-wheel__thumb {
  stroke: var(--ion-card-background, none);

  transition: inherit;
  transition-property: transform;
}

.syn-color-wheel.syn-color-wheel--grade-grade_a .syn-color-wheel__thumb { transform: rotate(30deg); }
.syn-color-wheel.syn-color-wheel--grade-grade_b .syn-color-wheel__thumb { transform: rotate(90deg); }
.syn-color-wheel.syn-color-wheel--grade-grade_c .syn-color-wheel__thumb { transform: rotate(150deg); }
.syn-color-wheel.syn-color-wheel--grade-grade_d .syn-color-wheel__thumb { transform: rotate(210deg); }
.syn-color-wheel.syn-color-wheel--grade-grade_e .syn-color-wheel__thumb { transform: rotate(270deg); }
.syn-color-wheel.syn-color-wheel--grade-grade_f .syn-color-wheel__thumb { transform: rotate(330deg); }
.syn-color-wheel.syn-color-wheel--grade-unknown .syn-color-wheel__thumb,
.syn-color-wheel.syn-color-wheel--grade-skeleton .syn-color-wheel__thumb { transform: rotate(0deg); }
