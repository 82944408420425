/**
 * Unfortunately no possible to adjust stroke width 20 of 48
 * https://github.com/ionic-team/ionic-framework/issues/18115
 */
ion-spinner.syn-splash-spinner {
  width: 128px;
  height: 128px;
  max-width: 100%;
  max-height: 100%;
}

.syn-splash-text {
  color: var(--ion-text-color);

  /** Color mode application */
  transition: color 250ms;
}
