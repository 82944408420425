/** Description list */
.syn-guide-description {
  margin: 0;
}

dl.syn-guide-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: calc(var(--ion-margin, 16px) * 2) 0;
}

dt.syn-guide-list__term,
dd.syn-guide-list__description {
  margin-bottom: 25px;
}

/** Definition term */
dt.syn-guide-list__term {
  width: 80px;
  height: 80px;

  display: flex;
  flex-basis: 80px;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  overflow: hidden;
  overflow: clip;
  text-overflow: ellipsis;
  color: var(--ion-color-light, #fff);
}

.syn-guide-term-grade--grade_a { background-color: #54d781; }
.syn-guide-term-grade--grade_b { background-color: #c0e36e; }
.syn-guide-term-grade--grade_c { background-color: #ffe851; }
.syn-guide-term-grade--grade_d { background-color: #ff854f; }
.syn-guide-term-grade--grade_e { background-color: #ea3a39; }
.syn-guide-term-grade--grade_f { background-color: #af0100; }

/** Avg to not use gradients */
.syn-guide-term-grade--grade_a-b { background-color: #8add78; }
.syn-guide-term-grade--grade_c-d { background-color: #ffb750; }
.syn-guide-term-grade--grade_e-f { background-color: #cd1e1d; }

.syn-guide-term-grade--unknown { background-color: #808080; }

/** Definition description */
dd.syn-guide-list__description {
  flex-basis: calc(100% - 100px);
  margin-left: 20px;
}

dd.syn-guide-list__description p {
  margin-top: 0;
  margin-bottom: calc(var(--ion-margin, 16px) / 2);
}

.syn-guide-list__description p:first-child { margin-top: 0; }
