.syn-logotype {
  display: flex;
  align-items: center;

  color: var(--ion-color-primary);

  cursor: default;
  user-select: none;
}

.syn-logotype > .syn-logotype__logo,
.syn-logotype > .syn-logotype__brand { pointer-events: none; }

/**
 * Logo
 */
.syn-logotype__logo {
  width: 24px;
  height: 24px;
}

.syn-logotype__logo > path { fill: currentColor; }

.syn-logotype.syn-logotype--toolbar {
  justify-content: center;
}

.syn-logotype--toolbar > .syn-logotype__logo {
  width: 32px;
  height: 32px;
}

/**
 * Brand
 */
.syn-logotype__brand {
  min-height: 20px;

  /** Should be Neris Black */
  font-family: "Neris Black", "Helvetica Neue", "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}

.syn-logotype__logo + .syn-logotype__brand {
  margin-left: 10px;
}

/**
 * Fit logotytpe on iPhone 5/SE
 * Note: Do not use transform: scale, it breaks centering when margin is used inside
 */
@media (max-width: 320px) {
  .syn-logotype--toolbar > .syn-logotype__logo {
    width: 28px;
    height: 28px;
  }

  .syn-logotype--toolbar > .syn-logotype__logo + .syn-logotype__brand {
    margin-left: 8px;
    font-size: 19.25px;
  }
}
