ion-skeleton-text.syn-skeleton-text {
  display: inline-block;
  max-width: 100%;

  /** Inherit height from font-size */
  line-height: 1em;
}

/** No margin required for single line of text */
ion-card-title > ion-skeleton-text.syn-skeleton-text {
  margin-top: 0;
  margin-bottom: 0;
}
