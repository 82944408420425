/** Alternative to scrollY={false} */
ion-content.syn-map-page__content {
  --overflow: hidden;
}

/**
 * Fix horizontal stretch on iOS, excluding header
 * Note: Only required when using Collapsible Large Titles​ on iOS
 */
/*
ion-content.syn-map-page__content::part(scroll) {
  display: flex;
  align-items: center;
  flex-direction: column;
}
*/

.syn-map {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow: clip;
  contain: strict;
  background-color: var(--syn-map-background-color, #ddd);
  z-index: 0; /** Safari fix */
}

.syn-map > .syn-map-container {
  width: inherit;
  height: inherit;
  background-color: inherit;
}

.syn-map-symbols { visibility: hidden; }

/**
 * Available panes (over overlay 400, under markers and shadow)
 * Popup:   700
 * Tooltip: 650
 * Marker:  600
 * Shadow:  500
 * Overlay: 400
 * Tile:    200
 */
.leaflet-shadow-pane {
  opacity: .5;
  mix-blend-mode: var(--syn-map-shadow-mix-blend-mode, multiply);
}

/**
 * Tweak MarkerCluster.css
 */
.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow,
.leaflet-cluster-spider-leg {
  transition-duration: .2s;
}

ion-fab-list {
  right: 0;
  align-items: flex-end;
}

/*
ion-fab-button.syn-filter-button {
  width: auto;
  min-width: 40px;

  --border-radius: 1em;
}
*/

ion-fab-button.syn-filter-button::part(native) {
  contain: content;
}

/**
 * Problem: Cannot style shadow element
 */
ion-fab-button .button-inner {
  position: relative;
}

/**
 * Leaflet controls
 */
body.dark .leaflet-container .leaflet-control-attribution { background-color: rgba(0, 0, 0, 0.7); }
