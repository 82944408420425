/**
 * Marker cluster icon
 */

/**
 * Icon (Counter)
 */
.syn-map-marker-cluster-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  background-color: rgba(var(--ion-background-color-rgb), 0.5);

  color: var(--ion-color-primary);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1ch;
}

.syn-map-marker-cluster-icon.leaflet-marker-icon { display: flex; }

.syn-map-marker-cluster-icon.syn-map-marker-cluster-icon--count-1d { font-size: 1.00em }
.syn-map-marker-cluster-icon.syn-map-marker-cluster-icon--count-2d { font-size: 1.50em }
.syn-map-marker-cluster-icon.syn-map-marker-cluster-icon--count-3d { font-size: 1.75em }
