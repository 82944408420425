/**
 * Stretch title as in iOS to center logotype
 * Should add padding, too
 */
ion-title.syn-title.md {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
