.syn-bgimage.syn-bgimage--station {
  max-height: 110px;
}

@media (min-width: 576px) {
  .syn-bgimage.syn-bgimage--station { max-height: 200px; }
}

/**
 * Pill-style Segments
 */
ion-segment.syn-select-stations {
  --background: initial;

  padding-top: 30px;
  padding-bottom: 15px;

  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;

  scrollbar-width: thin;
  scrollbar-color: var(--ion-color-light-shade) transparent;
}

/** Entire scrollbar */
ion-segment.syn-select-stations::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

/* Thumb */
ion-segment.syn-select-stations::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: 5px;

  background-color: var(--ion-color-light-shade);
  background-clip: content-box;
}

ion-segment.syn-select-stations::-webkit-scrollbar-thumb:active {
  background: var(--ion-color-primary);
  background-clip: content-box;
}


/**
 * @link https://ionicframework.com/docs/v5/api/segment-button
 */
ion-segment-button.syn-select-station {
  --background-checked: var(--ion-color-primary);

  --border-color: var(--ion-color-primary);
  --border-radius: 2em;
  --border-style: solid;
  --border-width: 1px;

  --padding-start: 12px;
  --padding-end: 12px;

  /** Hide moving indicator */
  --indicator-color: none;
  --indicator-height: 0;

  --color: var(--ion-color-primary);
  --color-checked: var(--ion-color-light);

  flex-grow: 0;

  min-width: 75px;
  min-height: 24px;

  margin-inline-start: auto;
  margin-inline-end: auto;

  border-style: var(--border-style);
  border-color: var(--border-color);
  border-width: var(--border-width);

  scroll-snap-align: center center;
}

ion-segment-button.syn-select-station::before { display: none; }

ion-segment-button.syn-select-station.segment-button-has-label-only ion-label {
  margin-top: 0;
  margin-bottom: 0;
}

/** Allow overflow. Doesn't work on Safari 12 which deesn't support ::part so value & unit doesn't show */
ion-segment-button.syn-select-station::part(native) {
  overflow: visible;
  contain: layout;
  text-transform: initial;
  border-radius: inherit;
}

/** Sub elements */
.syn-select-station__value-unit {
  position: absolute;
  margin-bottom: .5rem;
  bottom: 100%;

  color: var(--ion-color-primary);
}

.syn-select-station__value { font-weight: 700; }
.syn-select-station__unit { margin-left: .5ch; }

.syn-select-station__type {
  font-weight: 300;
  text-transform: uppercase;
}


/**
 * Source
 */
.syn-station-source {
  display: block;
  font-size: .85em;
}
