/**
 * @link http://cargocollective.com/eimantas/neris-1
 * @link https://www.fontsquirrel.com/fonts/neris
 */

/** Black */
@font-face {
  font-display: fallback;
  font-family: 'Neris Black';
  src: url('neris-black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
