.syn-map-marker-my-location-icon {
  z-index: 999;

  contain: strict;
  content-visibility: auto;
}

.syn-map-marker-my-location-icon__inner {
  display: block;
  width: inherit;
  height: inherit;

  border: 3px solid white;
  border-radius: 50%;
  background-color: #2a93ee;

  animation: syn-map-marker-my-location-icon-throb 4s ease infinite;
}

@keyframes syn-map-marker-my-location-icon-throb {
  50% { border-color: rgba(255, 255, 255, .8); }
}
