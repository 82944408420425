::selection {
  background-color: rgba(var(--ion-color-primary-rgb), 0.76);
  color: var(--ion-color-primary-contrast, #fff);
}

/** React-router links */
.syn-link { text-decoration: none; }

/**
 * Modal with auto-size
 * @see https://ionicframework.com/docs/api/modal#customization
 * @see https://ionicframework.com/docs/api/modal#css-custom-properties
 */
.syn-modal {
  --height: initial;
  --width: initial;

  --max-height: 90%;
}

/** Reset absolute positioning to allow height fitting */
.syn-modal > .modal-wrapper > .ion-page {
  position: relative;
  contain: layout style;
}

/** As with ion-loading */
.syn-modal > .modal-wrapper {
  padding-top: 24px;
  padding-bottom: 24px;
}

.syn-modal > .modal-wrapper.sc-ion-modal-md {
  padding-inline-start: 24px;
  padding-inline-end: 24px;
}

.syn-modal > .modal-wrapper.sc-ion-modal-ios {
  padding-inline-start: 34px;
  padding-inline-end: 34px;
}

/**
 * Platform incompatible page
 */
.syn-platform-incompatibile {
  align-items: center;
  justify-content: center;
}

.syn-platform-incompatibile > ion-card > * {
  display: block;
}

.ion-margin-start {
  /** Safari 12.0 workaround */
  margin-left: var(--ion-margin, 16px);
}

/**
 * Reordering
 */
/** Remove shadow for reorder items (looks bad for cards) */
ion-reorder.reorder-selected {
  box-shadow: none;
  opacity: 1;
}

ion-reorder.reorder-selected > ion-card {
  transform: rotate(2deg);
  box-shadow: 0px 6px 12px 2px rgb(0, 0, 0, 10%);
}

/** Fix padding on Chrome 60 (need to specify units for fallback value) */
ion-list-header { -webkit-padding-start: calc(var(--ion-safe-area-left, 0px) + 16px) }
