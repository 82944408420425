.syn-dashboard-reading {
  display: flex;
  flex-direction: row;
}

.syn-dashboard-reading + .syn-dashboard-reading { margin-top: 2px; }

/** Cart title */
.syn-station-reading-address {
  margin-right: var(--ion-margin, 16px);
}

.syn-station-reading-city {
  font-weight: 300;
}

.syn-dashboard-reading__description {
  flex: 1;
  overflow-y: hidden;
}

.syn-dashboard-reading--type-mono .syn-dashboard-reading__description {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.syn-dashboard-reading__data { flex: 0 0 100px; }
.syn-dashboard-reading--type-6-grade .syn-dashboard-reading__data { flex-basis: 140px; }


/**
 * Strategies to handle long labels
 * - Break words (now)
 * - Clip with text-overflow: ellipsis
 * - Use soft-hyphen (&shy;)
 * - Use hyphens: auto + lang="de"
 */
.syn-station-reading-norm-label {
  font-size: 23px !important;
  font-weight: 700 !important;
  line-height: 1 !important;

  word-break: normal;
  overflow-wrap: anywhere; /** Chrome 80+ */
}

@media (min-width: 576px) {
  .syn-station-reading-norm-label { font-size: 30px !important; }
}

.syn-station-reading-description {
  line-height: 1.25;
}

.syn-station-reading-date {
  margin-top: .5rem !important;
  font-weight: 300 !important;
}


.syn-station-reading-basic {
  text-align: center;
}

.syn-station-reading-sensor-type,
.syn-station-reading-basic__value {
  font-weight: 700;
}

.syn-station-reading-basic__unit {
  font-weight: 300;
}
