/**
 * Responsive breakpoints
 * @link https://ionicframework.com/docs/layout/css-utilities#responsive-display-attributes
 *
 * breakpoint | min-width
 *       ---: | ---
 *         sm |  576px
 *         md |  768px
 *         lg |  992px
 *         xl | 1200px
 *
 * Icons defaults
 * - font-size: 24px
 *   - small: 18px,
 *   - large: 32px
 * --ionicon-stroke-width: 32px;
 */

:root {
  accent-color: var(--ion-color-primary);
}

/** Add transition when switching color modes, applies ton splash page */
body {
  transition: background-color 250ms;
}

/**
 * Platform incompatible overlay page
 */
.syn-platform-incompatibile {
  align-items: center;
  justify-content: center;

  /** Fix fonts flash --ion-font-family/ --ion-default-font */
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.syn-platform-incompatibile > ion-card > ion-card-header,
.syn-platform-incompatibile > ion-card > ion-card-content {
  padding: 16px;
  display: block;
}

.syn-platform-incompatibile > ion-card > ion-card-content {
  padding-top: 0;
  font-size: 14px;
  line-height: 1.5;
}

/**
 * Buttons
 */

/** Disable text */
ion-back-button::part(text) { display: none; }

/**
 * Card
 */
ion-card {
  margin: 15px;

  border-radius: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

@media (min-width: 678px) {
  ion-card { margin: 20px; }
}

ion-card-title,
ion-card-subtitle {
  --color: var(--ion-card-color, --ion-color-step-850);
}

ion-card-title {
  font-size: 16px;
  font-weight: normal;
}

/** Increase size by +2px */
ion-card-content.card-content-md h1 { font-size: 26px; }
ion-card-content.card-content-md h2 { font-size: 18px; }

ion-card-content.card-content-md,
ion-card-content.card-content-md h3,
ion-card-content.card-content-md h4,
ion-card-content.card-content-md h5,
ion-card-content.card-content-md h6,
ion-card-content.card-content-md p { font-size: 16px; }

/**
 * Card: Section heading (inside ion-card-header ion-content)
 */
.syn-card-section-heading {
  display: inline-block;
  width: 58%;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  font-size: 15px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;

  border-bottom: 1px solid currentColor;
}

/**
 * List
 */
ion-item {
  --background: none;
  --color: var(--ion-color-medium);
}

/**
 * Searchbar
 */
ion-searchbar {
  --ion-background-color: var(--syn-component-background-color);
  --background: var(--syn-component-background-color);

  caret-color: var(--ion-color-primary);
}

/**
 * Tweak views/ pages, replace display: none with content-visibility: hidden
 * @see https://web.dev/content-visibility/
 * Note: Breaks when going back to page
 */
/*
@supports (content-visibility: hidden) {
  .ion-page.ion-page-hidden {
    display: flex !important;
    content-visibility: hidden;
  }
}
*/

/**
 * Ionic Refresher + Virtual Scroll fix in @ionic/core > 6.7.5
 * https://github.com/ionic-team/ionic-framework/pull/27619
 */
.ion-content-scroll-host {
  overflow-y: auto;
}

/**
 * Background image elements
 */
.syn-bgimage {
  display: block;
  width: 100%;
  height: auto;

  object-fit: contain;
  object-position: center bottom;

  z-index: -1;
}
