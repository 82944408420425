/**
 * Marker icons
 */

/**
 * Icon (Dot)
 */
.syn-map-marker-icon {
  width: 16px;
  height: 16px;
  background-color: currentColor;

  border: 1px solid rgba(var(--ion-color-light-contrast-rgb), .25);
  border-radius: 50%;
}

/**
 * Hide icon when far away so lone markers won't look lame.
 * Cannot use opacity as it's modified by marker cluster package
 */
.syn-map-container.syn-map-container--zoom-distant .syn-map-marker-icon {
  display: none;
}

/** Touch */
@media (pointer: coarse) {
}

/** Mouse */
@media (pointer: fine) {
  .syn-map-marker-icon:hover { border-color: rgba(var(--ion-color-light-contrast-rgb), .75); }
}

/** Indicate hover and current states */
.syn-map-marker-icon.syn-map-marker-icon--current {
  z-index: 600 !important;
  border-width: 2px;
  border-color: rgba(var(--ion-color-light-contrast-rgb), .75);
}
