/** Content wrapper */
.syn-dashboard-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  transition: background-color 250ms ease-in-out;
}

/**
 * Note: This doesn't cover the ion-content::part(background) with bounce effect (overscroll affordance) on iOS
 * Possible fixes:
 * - Use separate element for a fixed backdrop
 * - Disable effect on ion-content { overscroll-behavior-y: none; } when supported by Safari
 */
.syn-dashboard-content.syn-dashboard-content--reordering {
  background-color: rgba(var(--ion-color-primary-rgb), .25);
}

.syn-dashboard-content.syn-dashboard-content--reordering .syn-dashboard-station {
}

.syn-dashboard-content.syn-dashboard-content--reordering .syn-dashboard-station::part(native) { cursor: grab; }

.syn-dashboard-content.syn-dashboard-content--reordering .syn-dashboard-station.syn-dashboard-station--reorder-locked {
  opacity: 0.5;
  pointer-events: none;
}

.syn-bgimage.syn-bgimage--dashboard {
  flex-grow: 1;
  margin-top: -24%;
  margin-bottom: 70px;
}

@media (min-width: 992px) {
  .syn-bgimage.syn-bgimage--dashboard {
    margin-top: -180px;
    max-height: 480px;
  }
}

/**
 * Footer
 * Privacy policy + Copyright
 */
.syn-dashboard-footer {
  font-size: .75rem;
}

.syn-dashboard-footer ion-toolbar {
  --min-height: 28px;
}

.syn-dashboard-footer a {
  text-decoration: none;
}
