ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

/**
 * Material Design
 */
ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-list#menu-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

/** Header */
ion-menu.md ion-list-header {
  /** Similar to .item-native */
  padding-inline-start: calc(var(--padding-start) + var(--ion-safe-area-left, 0px));
  padding-inline-end: var(--padding-end);

  margin-bottom: 30px;
}

/** Note: same styles as in Logotype component */
ion-menu.md ion-list#menu-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;

  text-transform: uppercase;
  color: var(--ion-color-primary);
}

/** Menu items */
ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}


/**
 * iOS
 */

/** Content */
ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list-header {
  padding-left: 16px;
  padding-right: 16px;
}

/**
 * Common
 */
ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-menu ion-list#menu-list .syn-menu-logo {
  margin-right: 10px;
}
