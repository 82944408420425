/**
 * Common styles for icon and marker cluster icon
 *
 * Elements overview
 * .syn-map-marker-icon:         Marker icon
 * .syn-map-marker-cluster-icon: Cluster icon
 * .syn-map-marker-cloud:        Marker shadow, Cluster shadow
 * .syn-map-marker--grade-*:     Marker icon, Marker shadow, Cluster shadow
 */

/** Performance boost */
.syn-map-marker-icon,
.syn-map-marker-cluster-icon,
.syn-map-marker-cloud {
  contain: strict;
  content-visibility: auto;
}

.syn-map-marker-icon,
.syn-map-marker-cluster-icon {
  /** Set :focus/ :focus-visible outline styles */
  outline-offset: 5px;
  outline-width: 3px;
}

/**
 * Tab navigation
 * Add styles to leaflet 1.8 tab navigation
 *
 * Note: There os a bug in Safari that makes outline square
 * @link https://bugs.webkit.org/show_bug.cgi?id=20807
 * Current workaround: use :focus-visible (Safari 15.4+) instead of :focus
 * Possible workaround: https://stackoverflow.com/a/69679567/1012616
 */
.syn-map-marker-icon:focus-visible,
.syn-map-marker-cluster-icon:focus-visible {
  outline-style: solid;
  outline-color: rgba(var(--ion-color-primary-rgb), .5);

  /** Over other markers, but below tooltip and popup panes */
  z-index: 601 !important;
}

.syn-map-marker--grade-grade-a { color: #54d781; }
.syn-map-marker--grade-grade-b { color: #c0e36e; }
.syn-map-marker--grade-grade-c { color: #ffe851; }
.syn-map-marker--grade-grade-d { color: #ff854f; }
.syn-map-marker--grade-grade-e { color: #ea3a39; }
.syn-map-marker--grade-grade-f { color: #af0100; }

/**
 * Shadow (Cloud)
 */
.syn-map-marker-cloud {
  background: currentColor;
  background: radial-gradient(circle closest-side, currentColor 50%, transparent);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;

  pointer-events: none;
}
