/**
 * Searchbar
 */
.syn-map-search {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;

  /** Over any .leaflet-pane (at least 400+) */
  z-index: 1000;

  cursor: initial;
}

ion-searchbar.syn-map-search-bar {
  --background: var(--ion-background-color, #fff);
  --border-radius: 99em;
  --box-shadow: none;

  z-index: 2;
}

/**
 * List (query hints)
 */
ion-list.syn-map-search-query-hint-list {
  z-index: 1;
}

ion-list.syn-map-search-query-hint-list {
  --ion-item-background: var(--syn-component-background-color, #fff);

  margin-inline-start: 8px;
  margin-inline-end: 8px;

  padding-top: 0;
  padding-bottom: 0;

  /** Copied from .sc-ion-searchbar-md-h */
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12)
  ;
}

ion-list.syn-map-search-query-hint-list.list-inset {
  margin-top: 0;
}

/**
 * List item
 */
ion-list.syn-map-search-query-hint-list ion-label {
  --min-height: 32px;

  padding-left: 40px;
}

ion-list.syn-map-search-query-hint-list.list-inset ion-label {
  padding-left: 30px;
}

ion-list.syn-map-search-query-hint-list ion-label > .syn-station-city { font-weight: bold; }

ion-list.syn-map-search-query-hint-list ion-label > .syn-station-address { margin-top: 6px; }

ion-list.syn-map-search-query-hint-list ion-label > .syn-station-address,
ion-list.syn-map-search-query-hint-list ion-label > .syn-station-city { display: block; }
