.syn-weather-item {}

.syn-weather-item__icon > .syn-weather-item__icon-svg {
  width: 48px;
  height: 48px;

  color: var(--ion-color-primary);
}

.syn-weather-item__value {
  margin-bottom: .5rem;

  font-weight: 700;
  line-height: 1;
}

.syn-weather-item__unit {
  opacity: .75;
}
