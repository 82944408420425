/**
 * Wrapper
 */
.syn-chart {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  height: 125px;
}

.syn-chart__spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.syn-chart__element {
  width: 100%;
  height: 100%;

  /** Invert css coords */
  transform: scale(1, -1);
}

.syn-chart__no-data {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.syn-chart-item {
}

.syn-chart-item__bar {
  fill: #888;
  fill-opacity: .75;

  transition-property: height, fill, fill-opacity;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

.syn-chart-item__bar.syn-chart-item--grade-grade-a { fill: #54d781; }
.syn-chart-item__bar.syn-chart-item--grade-grade-b { fill: #c0e36e; }
.syn-chart-item__bar.syn-chart-item--grade-grade-c { fill: #ffe851; }
.syn-chart-item__bar.syn-chart-item--grade-grade-d { fill: #ff854f; }
.syn-chart-item__bar.syn-chart-item--grade-grade-e { fill: #ea3a39; }
.syn-chart-item__bar.syn-chart-item--grade-grade-f { fill: #af0100; }
.syn-chart-item__bar.syn-chart-item--grade-unknown { }

.syn-chart-item__label {
  display: none;
  transform: scale(1, -1);

  fill: var(--ion-text-color, #888);
  opacity: .75;

  text-anchor: middle;
  font-size: 12px;

  cursor: default;
}

.syn-chart-item:nth-child(4n + 2) > .syn-chart-item__label { display: initial; }

@media (min-width: 768px) {
  .syn-chart-item:nth-child(2n + 0) > .syn-chart-item__label { display: initial; }
}

@media (min-width: 1200px) {
  .syn-chart-item > .syn-chart-item__label { display: initial; }
}

/*
.syn-chart-item:hover > .syn-chart-item__bar { fill-opacity: 1; }
.syn-chart-item:hover > .syn-chart-item__crosshair { fill: rgba(127, 127, 127, .0625); }
.syn-chart-item:hover > .syn-chart-item__label { opacity: 1; }
*/
